.form-card{
	align-items: stretch;
    background: RGB(var(--card-color));
    border-radius: 50px;
	box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.3), 3px 3px 6px 0 rgba(240, 240, 240, 0.15) inset;
    display: flex;
    flex-direction: column;
    padding: 1.4rem 2.1rem;
    width: 100%;
	margin: 1rem 0;


	@media (max-width: 768px){
		padding: 2.1rem;
		margin: 2.1rem auto .7rem;
	}


	.form-title{
		@media (max-width: 767px){padding: 0;}

	    align-items: baseline;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.4rem;
		padding-bottom: 1.4rem;
		width: 100%;
	}


	> .form-body{
		width: 100%;
		position: relative;
	}

}
